/* Mantine styles */
/* src/index.css                */
@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/charts/styles.css';
@import 'react-awesome-button/dist/styles.css';
@import '@mantine/carousel/styles.css';
@import '@mantine/notifications/styles.css';


/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif; /* Default font, you can change it based on your design */
  background-color: #f4f4f4; /* Light background, adjust according to your theme */
  color: #333; /* Default text color, should be readable against the background */
}


/* In your CSS file */



a {
  color: inherit; /* Makes links use the current text color by default */
  text-decoration: none; /* Removes underline from links */
}

/* Utility classes */
.container {
  max-width: 1200px; /* Maximum width of the content */
  margin: 0 auto; /* Centers the content */
  padding: 20px; /* Adds some padding inside the container */
}

/* Add more custom styles as needed... */
